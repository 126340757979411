import styled from 'styled-components';

import { Section } from '@local/components';

export const StyledSection = styled(Section)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing[80]} 0;
  margin: 0;
`;

export const Footer = styled(Section)`
  background-color: ${({ theme }) => theme.color.lightGray3};
  margin: 0;
  padding: ${({ theme }) => theme.spacing[64]} 0
    ${({ theme }) => theme.spacing[80]};
`;

export const ImageWrapper = styled.div`
  max-width: 275px;
  margin: 0 auto;
`;
